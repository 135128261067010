import Modal from 'react-bootstrap/Modal';
import CreateMyCategoryForm from './MyCategoryForm';


interface IProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
	refetch?:()=>void
}

const AddMyCategoryDialog = ({ show, setShow,refetch }: IProps) => {
  return (
    <Modal
      size='sm'
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setShow(!show)}
      show={show}
    >
      <Modal.Header closeButton>
        <h4><b>add my category</b></h4>
      </Modal.Header>
      <CreateMyCategoryForm refetch={refetch} setShow={setShow} show={show} />
    </Modal>
  );
}

export default AddMyCategoryDialog;