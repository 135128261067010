import styled, { css } from "styled-components";

export const CategoryCardStyle = styled.div`
.box-heading-font{
    font-size: 2.5vw;
    color: #3F3A58;
    font-weight: bold; 
}
.box-footer-font{
    font-size: 2vw;
    color: #4A4B76;
    font-weight: bold;

}
.box-body{
    font-size: 4.8vw;
    color: #4A4B76 ;
    font-weight: bold;
}
.card{
    min-height:60vh;
    min-width:60vw;
    border-radius: 25px;
    border: 2px solid #9494adff;
    background-color: #dedce3ff;
    padding: '15px';
    user-select:None;
    cursor: pointer;
    
}
.plus_icon_width{
    font-size:2.8vw;
    color:#4B4C77;
}
.IoChevronWidth{
    font-size:4vw;
}






  /* Media Query */
  @media  (max-width: 549px) {
    .box-body{
        font-size:6.5vw
    }
    .box-heading-font{
        font-size:5vw
    }
    .box-footer-font{
        font-size:4vw
    }
    .plus_icon_width{
        font-size:8.5vw;
    }
    .card{
        min-width:65vw !important;
        min-height:55vh !important;
    }
    .IoChevronWidth{
        font-size:10.5vw;
    }
  }

  @media (max-width: 767px) and (min-width:550px){
    .box-body{
        font-size:5.5vw
    }
    .box-heading-font{
        font-size:4.5vw
    }
    .box-footer-font{
        font-size:3.8vw
    }
    .plus_icon_width{
        font-size:6vw;
    }
    .card{
        min-width:60vw !important;
        min-height:60vh !important;
    }
    .IoChevronWidth{
        font-size:8.5vw;
    }
    
  
  }

  @media screen and (max-width: 991px) and (min-width: 768px) {
    .box-body{
        font-size:4.5vw
    }
    .box-heading-font{
        font-size:3.5vw
    }
    .box-footer-font{
        font-size:3vw
    }
    .plus_icon_width{
        font-size:4.8vw;
    }
    .IoChevronWidth{
        font-size:7.5vw;
    }
  }
  @media (max-width: 1200px) and (min-width: 992px) {

    .plus_icon_width{
        font-size:4vw;
    }
    .IoChevronWidth{
        font-size:6vw;
    }
  }
  
  

`;
