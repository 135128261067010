import { StyledAbout } from "./style";
const AboutUs = () => {
	return (
		<StyledAbout>
			<div className="container-fluid col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 ">
				<div
					className="about d-flex flex-column "
					style={{ lineHeight: "1.8" }}
				>
					<div className="abou-us p-0">
						<h2 className="cmn-font-h2 text-center">
							<b>about us</b>
						</h2>
						<p className="cmn-font mt-3">
							<b>vokab</b> is a <b>student-led initiative</b> dedicated to
							bringing a large and high-quality set of vocabulary words to the
							language learners of the world. By mastering{" "}
							<b>niche vocabulary</b> only native speakers would know, language
							learners have the opportunity to reach a{" "}
							<b>new level of mastery</b> and break down{" "}
							<b>the language barriers</b> that separate us today.
						</p>
					</div>
					<div className="account p-0 d-flex justify-content-center mt-4 mb-0  col-11">
						<p className="cmn-font col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6 text-end ">
							<b>email us:</b> info@linguistics.world
						</p>
						{/* <p className="cmn-font col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6 text-center"><b>instagram:</b> @vokab2025</p> */}
					</div>
					<div className="hr m-0 p-0">
						<hr className="m-0" />
					</div>
					<div
						className="profile d-flex justify-content-start align-items-center flex-wrap p-0 mt-3 ms-5 col-12 mr-auto_m"
						style={{ letterSpacing: 1.2 }}
					>
						<p className="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-12 profile-font text-center">
							<b style={{ color: "#4b4d77" }}>Kabilan Prasanna</b>{" "}
							<b>
								<b style={{ color: "#4A4C75" }}>• </b>Founder
							</b>
						</p>
						<img
							src="../Asserts/profile.png"
							className="img-thumbnail rounded-circle col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-12 mx-auto_m"
							alt="profile"
						/>
					</div>
				</div>
			</div>
		</StyledAbout>
	);
};

export default AboutUs;
