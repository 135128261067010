/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Layout } from "../../home/Layout";
import { useUserContextWrapper } from "../../../shared/contexts/UserContextWrapper";
import { FlashCaredStyle } from "../flashcared";
import { MyCategoryDropdown } from "../myCategories/CategoriesDropDown";
import { SharedCategoryCards } from "./sharedCategoryCard";
import sharedCategoryAPI from "../../../store/services/sharedCategoryAPI";

export const SharedCategories = () => {
	const { user } = useUserContextWrapper();
	const [setAllDelivered] = sharedCategoryAPI.useSetAllDeliveredMutation();

	const [fetchAllByReceiverId, { data: sharedCategoriedByUserId }] =
		sharedCategoryAPI.useGetAllSharedCategoriesByReceiverIdMutation();
	// const { sharedCategoriesById } = useAppContextWrapper();
	const sharedCategoriesById = sharedCategoriedByUserId?.result?.data;

	useEffect(() => {
		if (user?.userId)

			fetchAllByReceiverId({
				to_user_id: user?.userId!,
			});
	}, [user]);
	useEffect(() => {
		setAllDelivered({ to_user_id: user?.userId! }).then(() => {
			console.log("set all delivered");
		});
	}, [fetch, sharedCategoriesById?.result, user]);

	return (
		<Layout>
			<div style={{ height: "calc(100vh - 220px)" }}>
				<MyCategoryDropdown text="shared categories" />
				<div className="col-9 mx-auto d-flex flex-wrap scrollbar scrollbar-primary">
					{sharedCategoriesById?.result?.map((x) => {
						return (
							<FlashCaredStyle
								key={x.customCategoryId}
								className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-12 mt-3"
							>
								<SharedCategoryCards
									categoryId={x.customCategoryId}
									categoryName={x.categoryName}
									sharedBy={x.displayName}
								/>
							</FlashCaredStyle>
						);
					})}
				</div>
			</div>
		</Layout>
	);
};
