/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import TextInput from "../../shared/components/form/TextInput";
import { LoginButton } from "../../styled-components/common";
import { FormikWithWrapper } from "../../shared/contexts/FormikWrapper";
import { userLogin } from "@vokab/shared/src/schemas";
import { Any, LoginPayload } from "@vokab/shared/src/types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContextWrapper } from "../../shared/contexts/UserContextWrapper";
import { FormStyle } from "./style";
import Form from "react-bootstrap/Form";
import { FormikProps } from "formik";
import { IoIosArrowBack } from "react-icons/io";
import { useAppHook } from "../../shared/hooks/useAppHook";
import Notify from "../../shared/components/Notify";
import authApi from "../../store/services/authAPI";
import { useNotificationContextWrapper } from "../../shared/contexts/NotificationContextWrapper";
import { useUserHook } from "../../shared/hooks/useUserHook";

const initialValues = {
	email: "",
	password: "",
};

const initValue = {
	email: "",
};

export default function Login() {
	const [showLoginForm, setShowLoginForm] = useState(false);
	const { sendForgotPasswordLink } = useAppHook();

	const handleForgotPasswordClick = () => {
		setShowLoginForm(!showLoginForm);
	};
	const { isLoggedIn } = useUserContextWrapper();

	const handleBackToLoginClick = () => {
		setShowLoginForm(!showLoginForm);
	};

	const navigate = useNavigate();
	const [login, { data, error }] = authApi.useUserLoginMutation();
	const [fogetPassword, { data: forPassData, error: forPassError }] =
		authApi.useForgetPasswordMutation();
	const { notifyError, notifySuccess } = useNotificationContextWrapper();
	const { getMe } = useUserHook();
	const submit = (values: LoginPayload) => {
		login(values);
	};

	const onSubmitForgot = async (values: typeof initValue) => {
		fogetPassword({
			email: values.email,
		})
			.then((res) => {
				notifySuccess("Password reset link sent to your email");
			})
			.catch((err) => {
				notifyError(err.message);
			});
	};

	useEffect(() => error && notifyError(error), [error]);
	useEffect(() => forPassError && notifyError(forPassError), [forPassError]);
	useEffect(() => {
		if (data?.result.data) {
			notifyError(data.result.data);
		}
	}, [forPassData]);
	useEffect(() => {
		if (data) {
			localStorage.setItem("authToken", data.result.data);
			getMe();
		}
	}, [data, getMe]);
	useEffect(() => {
		if (isLoggedIn) navigate("/");
	}, [navigate, isLoggedIn]);

	return (
		<>
			{!showLoginForm ? (
				<>
					<FormStyle>
						<div>
							<h3 className="text-center mt-3 form-title">
								<b>log into your account</b>
							</h3>
							<FormikWithWrapper
								enableReinitialize
								validateOnMount
								initialValues={initialValues}
								validationSchema={userLogin as Any}
								onSubmit={submit}
							>
								{(props: FormikProps<LoginPayload>) => (
									<Form
										className="pt-3"
										onSubmit={props.handleSubmit}
										noValidate
									>
										<TextInput
											name="email"
											type="email"
											placeholder="email@address.com"
										/>
										<TextInput
											name="password"
											type="password"
											placeholder="password"
										/>
										<div className="d-flex align-items-center justify-content-between flex-column">
											<div className=" pt-3 d-flex justify-content-end w-100 ">
												<a
													href="#"
													id="forgot-form"
													onClick={handleForgotPasswordClick}
													style={{ textDecoration: "none" }}
												>
													<b className="link-color">forgot password?</b>
												</a>
											</div>

											<div className="text-center ">
												<LoginButton
													type="submit"
													className="btn btn-primary form-button mx-auto m-3 rounded-5 "
												>
													log in
												</LoginButton>
											</div>
										</div>
									</Form>
								)}
							</FormikWithWrapper>
						</div>
					</FormStyle>
				</>
			) : (
				<>
					<FormStyle>
						<FormikWithWrapper
							enableReinitialize
							validateOnMount
							initialValues={initValue}
							validationSchema={userLogin.omit(["password"]) as Any}
							onSubmit={onSubmitForgot}
						>
							{(props: FormikProps<LoginPayload>) => (
								<Form
									className="pt-3 "
									onSubmit={props.handleSubmit}
									noValidate
								>
									<div
										style={{
											display: "flex",
											justifyContent: "center",
										}}
										className="form-title"
									>
										<b>forgot your password?</b>
									</div>
									<TextInput
										name="email"
										type="email"
										placeholder="email@address.com"
										className="mt-2"
									/>
									<div className="d-flex align-items-baseline mt-4">
										<a
											href="#"
											id="back-to-login"
											onClick={handleBackToLoginClick}
											style={{ textDecoration: "none" }}
										>
											<b className="link-color">
												<IoIosArrowBack />
												<IoIosArrowBack /> back to log in
											</b>
										</a>
									</div>
									<div className=" d-flex mt-4">
										<LoginButton
											type="submit"
											className="btn btn-primary mx-auto rounded-5  px-4 py-2 d-flex align-items-center justify-content-center"
										>
											<b
												style={{ fontSize: "1.4rem" }}
												className="text-center p-0"
											>
												reset my password
											</b>
										</LoginButton>
									</div>
								</Form>
							)}
						</FormikWithWrapper>
					</FormStyle>
				</>
			)}
			<Notify />
		</>
	);
}
