import styled  from "styled-components";
import { Col, Row } from "react-bootstrap";
import { dropdownContainer } from "../../home/styles";
import device from "../../../styled-components/device";
import { FlashCaredStyle } from "../flashcared";

const MyDropdownContainer = styled.div`
	${dropdownContainer}
		${device.md} {
			padding: 2.5vw !important;
			font-size: 2vw;
		}
		${device.xs} {
			padding: 1.5vw !important;
			font-size: 2vw;
		}
	
`

interface IProps {
  text: string;
}
export const MyCategoryDropdown = ({ text }: IProps) => {
  return (
    <>
      <FlashCaredStyle>
        <div className="container-fluid  col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-12">
          <Row className='m-0 mt-3 justify-content-between align-items-center '>
            <Col className="p-0 col-12">
              <MyDropdownContainer className="d-flex flex-wrap justify-content-center align-items-center   MyDropdownContainer my_vokab_table" >
                {/* <div className="col-1 d-flex justify-content-center align-items-center">
                  <FaSearch className="icon-width" />
                </div> */}
                <div className="col-12">
                  <FlashCaredStyle>
                    <select value={''} onChange={e => console.log} className="my_categories_font ps-0 bg-image" disabled>
                      {
                        <option key={1} value={text}>{text}</option>
                      }
                    </select>
                  </FlashCaredStyle>
                </div>
              </MyDropdownContainer>
            </Col>
          </Row>
        </div>
        <hr className="col-11 mx-auto "></hr>
      </FlashCaredStyle>
    </>
  );
}