import React, { useContext, createContext, useState } from 'react';
import { IAllUsers, ICategoriesTableData, ICategory, ICategoryById, ICategoryByProf, ICustomCategory, IGrammarCategory, ILanguage, IProfLevel, ISharedCategoriesByIdData, ISharedCategory } from '@vokab/shared/src/types';
import { StringLiteral } from 'typescript';

export interface ICategoriesByIdData {
	result: ICategoryById[];
}

export interface ICustomCategoriesByUserIdData {
	result: ICustomCategory[];
}

export interface ISharedCategoriesByUserIdData {
	result: ISharedCategory[];
}
export interface ICategoriesByProfData {
	result: ICategoryByProf[];
}
export interface IAppContextState {
	categories: ICategory[];
	categoriesByProf: ICategoriesByProfData;
	languages: ILanguage[];
	profLevels: IProfLevel[];
	categoriesTableData: ICategoriesTableData;
	pos: IGrammarCategory[];
	categoriesById: ICategoriesByIdData;
	myCategories: ICustomCategoriesByUserIdData;
	flashCardProfLevel: string;
	flashCardsMyCustomCategory: string;
	flashCardsSourceLang: string;
	flashCardsTargetLang: string;
	allUsers: IAllUsers[];
	sharedCategoriesById: ISharedCategoriesByIdData;
	isLoading:boolean;
	flashCardBowlCategory:string;
}

export interface AppContextProps {
	children: React.ReactNode;
}

const initialValues: IAppContextState = {
	categories: [],
	categoriesByProf: { result: [] },
	languages: [],
	profLevels: [],
	categoriesTableData: { totalRecords: 0, result: undefined },
	pos: [],
	categoriesById: { result: [] },
	myCategories: { result: [] },
	flashCardProfLevel: '',
	flashCardsMyCustomCategory: '',
	flashCardsSourceLang: 'English',
	flashCardBowlCategory: '',
	flashCardsTargetLang: 'Tamil',
	allUsers: [],
	sharedCategoriesById: { result: [] },
	isLoading:false
};

export interface IAppContext extends IAppContextState {
	appStateSetter: React.Dispatch<React.SetStateAction<IAppContextState>>;
}

const AppContext = createContext<IAppContext | null>(null);

export const useAppContextWrapper = () => useContext(AppContext) as IAppContext;

export const AppContextWrapper = ({ children }: AppContextProps) => {
	const [appState, appStateSetter] = useState<IAppContextState>(initialValues);

	return (
		<AppContext.Provider value={{
			...appState,
			appStateSetter
		}}>
			{children}
		</AppContext.Provider>
	);
}