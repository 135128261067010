import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

type GetAllSharedCategoriesByReceiverIdResponse = {
	status: number;
	name: string;
	message: string;
	result: {
		data: {
			totalRecords: number;
			result: {
				customCategoryId: string;
				categoryName: string;
				displayName: string;
				userId: string;
			}[];
		};
	};
};

type setDeliveredResponseProps = {
	status: number;
	name: string;
	message: string;
	result: {
		data: {
			totalRecords: number;
			result: "shared category updated successfully";
		};
	};
};
type setAllDeliveredArgs = {
	to_user_id: string;
};
type GetAllSharedCategoriesByReceiverIdArgs = {
	to_user_id: string;
};
type getAllSharedByPersonalCategoryIdResponse = {
	status: number;
	name: string;
	message: string;
	result: {
		data: {
			totalRecords: number;
			result: {
				shared_category_id: string;
				from_user_id: string;
				to_user_id: string;
				custom_category_id: string;
				is_delivered: boolean;
				users_shared_category_from_user_idTousers: {
					user_id: string;
					first_name: string;
					last_name: string;
					email: string;
					display_name: string;
					is_email_verified: boolean;
					email_verification_token: string;
					password: string;
					salt: string;
					updated_at: string;
					verification_hash: string | null;
				};
				users_shared_category_to_user_idTousers: {
					user_id: string;
					first_name: string;
					last_name: string;
					email: string;
					display_name: string;
					is_email_verified: boolean;
					email_verification_token: string;
					password: string;
					salt: string;
					updated_at: string;
					verification_hash: string | null;
				};
			}[];
		};
	};
};


const sharedCategoryAPI = createApi({
	reducerPath: "api/sharedCategory",
	baseQuery: fetchBaseQuery({ baseUrl: "/api" }),
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: false,
	tagTypes: ["SharedCategory"],
	endpoints: (builder) => ({
		getAllSharedCategoriesByReceiverId: builder.mutation<
			GetAllSharedCategoriesByReceiverIdResponse,
			GetAllSharedCategoriesByReceiverIdArgs
		>({
			query: (args) => ({
				method: "POST",
				url: `common/sharedCategories`,
				body: {
					userId: args.to_user_id,
				},
				cacheTime: 10000,
			}),
			onCacheEntryAdded(arg, api) {
				console.log("onCacheEntryAdded", arg, api);
			},
		}),
		getAllSharedByPersonalCategoryId: builder.mutation<getAllSharedByPersonalCategoryIdResponse,any>({
			query: (args) => ({
				method: "POST",
				url: `common/categories/shared`,
				body: args,
				cacheTime: 10000,
			}),
		}),

		getAllUnDeliveredByReceiverId: builder.mutation<
			setDeliveredResponseProps,
			setAllDeliveredArgs
		>({
			query: (args) => ({
				method: "POST",
				url: `common/sharedCategories/undelivered`,
				body: args,
				cacheTime: 10000,
			}),
		}),
		setAllDelivered: builder.mutation<
			setDeliveredResponseProps,
			setAllDeliveredArgs
		>({
			query: (args) => ({
				method: "POST",
				url: `common/sharedCategories/setDelivered`,
				body: args,
				cacheTime: 10000,
			}),
		}),
	}),
});

export default sharedCategoryAPI;
