import styled from 'styled-components';

export const FlashCaredStyle = styled.div`

  .header button, input, optgroup, select, textarea {
    background: none !important;
    border: none !important;
    font-weight: bold;
    appearance: none;
  }
  .my_vokab_table{
    background-color: #DEDCE3;
    border: 3px solid #4B4C77;
    border-radius: 1.4vw;
    padding: 0.6vw;
  }
  .col-1{
    width:2%;
  }
  .col-11{
    width:98%;
  }
  .search.col-11{
    width:96%;
  }
  .search.col-1{
    width:4%;
  }

  .form-control{
    border:none !important;
    border-color:transparent !important;

  }
  .icon-width{
    font-size:2rem;
  }
  .icon-width_2{
    font-size:3.5vw;
  }
  .col-6{
    width:49.8%;
  }

  .my_categories_font{
    font-size: 1.5vw;
    font-weight: bold;
    color: #4B4C77 !important;
    text-align:center;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #4A4C75 !important;
  }



  select {
    appearance: none !important;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" fill="rgb(74, 77, 119)"/></svg>') !important;
    background-repeat: no-repeat !important;
    background-position: right center !important;
    background-size: 16px !important;
    width: 100% !important;
    right: 0px !important;
    cursor: pointer;
    border:none;
    outline:none;
  
  }
  select .option{
    text-align:left;
    font-weight: bold;
    color:#4B4D77;
    text-decoration: underline ;

  }
  .bg-image {
    background-image: none !important;
    cursor: default;
  }
  .card{
      min-width: 15vw;
      max-width: 15vw;
      // max-height:5vw;
      // overflow-y:auto;
      border: 2px solid #9494adff !important;
      border-radius: 25px;
      background-color: #dedce3ff;
      min-height: 30vh;
      cursor: pointer;
  }
  .my_categories_font{
    font-size: 1.5vw;
    font-weight: bold;
    color: #4B4C77 !important;
}
.card-wrapper{
  margin-top:1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; 
}
.MyDropdownContainer {
  padding: 0.6vw !important;
  
}
hr.col-11{
  width:90%;
  opacity:.75
}

/* Media Query */
  @media  (max-width: 549px) {
    .icon-width{
      font-size:5vw;
    }
    .card{
      min-width:55vw;
      min-height:25vh !important;
    }
    .my_categories_font{
      font-size:4.5vw;
    }

 

  }
  @media (max-width: 767px) and (min-width: 550px) {
    .icon-width{
      font-size:3vw;
    }
    .card{
      min-width:40vw;
    }
    .my_categories_font{
      font-size:3vw;
    }


  
  }

  @media screen and (max-width: 991px) and (min-width: 768px) {
    .icon-width{
      font-size:2.5vw;
    }
    .card{
      min-width:25vw;
    }
    .my_categories_font{
      font-size:2vw;
    }

  
    
  }

  @media (max-width: 1200px) and (min-width: 992px) {
 
    .icon-width{
      font-size:2vw;
    }
    .card{
      min-width:20vw;
    }
   

    
  }
`;



