/* eslint-disable react-hooks/exhaustive-deps */
import TextInput from "../../shared/components/form/TextInput";
import { LoginButton } from "../../styled-components/common";
import { FormikWithWrapper } from "../../shared/contexts/FormikWrapper";
import { Any, LoginPayload, ResetPasswordPayload } from "@vokab/shared/src/types";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormStyle } from "./style";
import Form from 'react-bootstrap/Form';
import { FormikProps } from "formik";
import { resetPasswordSchema } from "@vokab/shared/src/schemas";
import Notify from "../../shared/components/Notify";
import authApi from "../../store/services/authAPI";
import { useNotificationContextWrapper } from "../../shared/contexts/NotificationContextWrapper";

const initialValues = {
  password: '',
  confirmPassword: ''
};

export default function ResetPassword() {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const navigate = useNavigate();
	const {notifyError,notifySuccess}=useNotificationContextWrapper();
  const [searchParams] = useSearchParams();

  const userId = searchParams.get('userId')
	const [resetPassword,{data,error}]=authApi.useResetPasswordMutation()

	useEffect(() => error && notifyError(error), [error]);
	useEffect(() => {
		if (data) {
			notifySuccess('password reset successfully');
			navigate('/auth/login');
		}
	}, [data, navigate, notifySuccess]);



  const submit = async (values: ResetPasswordPayload) => {
    if (userId) {
      await resetPassword({
        userId: userId ?? '',
        password: values.password,
        confirmPassword: values.confirmPassword
      });
    }
  };

  return (
    <>
      <Notify/>
      <FormStyle>
        <div>
          <h3 className="text-center mt-3 form-title"><b>reset your password</b></h3>
          <FormikWithWrapper
            enableReinitialize
            validateOnMount
            initialValues={initialValues}
            validationSchema={resetPasswordSchema as Any}
            onSubmit={submit}
          >
            {
              (props: FormikProps<LoginPayload>) => (
                <Form className="pt-3" onSubmit={props.handleSubmit} noValidate>
                  <TextInput name="password" className="mb-1" type="password" placeholder="password" />
                  <TextInput name="confirmPassword" className="mb-1" type="password" placeholder="confirm password" />
                  <div className="d-flex align-items-center justify-content-between flex-column">
                    <div className="text-center ">
                      <LoginButton type="submit" className="btn btn-primary form-button mx-auto m-3 rounded-5 ">reset</LoginButton>
                    </div>
                  </div>
                </Form>
              )
            }
          </FormikWithWrapper>
        </div>
      </FormStyle>
    </>
  );
}