import * as Yup from 'yup';
import { ReactNode, RefObject, useContext, createContext } from 'react';
import { Formik, FormikConfig, FormikProps } from 'formik';
import { Any } from '@vokab/shared/src/types';

interface FormikWrapperProps<Values> extends FormikConfig<Values> {
	validationSchema: Yup.ObjectSchema<Any>;
	initialValues: Values;
	onSubmit: FormikConfig<Values>['onSubmit'];
	innerRef?: RefObject<FormikProps<Values>>
	children: ReactNode | ((props: FormikProps<Values>) => ReactNode);
}

interface FormikBugFixProps {
	validationSchema: Yup.ObjectSchema<Any>;
	children: React.ReactNode;
}

interface FormikBugFixContextType {
	validationSchema: Yup.ObjectSchema<Any>;
}

const FormikBugFixContext = createContext<FormikBugFixContextType>({
	validationSchema: Yup.object()
});

export const useFormikBugFixContext = () => useContext(FormikBugFixContext);

const FormikContextWrapper = ({ validationSchema, children }: FormikBugFixProps) => {
	const contextValue = {
		validationSchema
	};

	return <FormikBugFixContext.Provider value={contextValue}>{children}</FormikBugFixContext.Provider>;
};

export const FormikWithWrapper = ({ validationSchema, initialValues, onSubmit, children, ...rest }: FormikWrapperProps<Any>) => (
	<FormikContextWrapper validationSchema={validationSchema} >
		<Formik
			{...rest}
			validationSchema={validationSchema}
			initialValues={initialValues}
			onSubmit={onSubmit}
			
		>
			{children}
		</Formik>
	</FormikContextWrapper>
);