import React, { useContext, createContext, useState } from 'react';

export interface IMyCategoryContextState {
  categories: string;
  sourceLanguage: string;
  targetLanguage: string;
  categoryName: string;
}

export interface MyCategoryContextProps {
  children: React.ReactNode;
}

const initialValues = {
  categories: '',
  sourceLanguage: '',
  targetLanguage: '',
  categoryName: ''
};

export interface IMyCategoryContext extends IMyCategoryContextState {
  MyCategoryStateSetter: React.Dispatch<React.SetStateAction<IMyCategoryContextState>>;
}

const MyCategoryContext = createContext<IMyCategoryContext | null>(null);

export const useMyCategoryContextWrapper = () => useContext(MyCategoryContext) as IMyCategoryContext;

export const MyCategoryContextWrapper = ({ children }: MyCategoryContextProps) => {
  const [myCategoryState, MyCategoryStateSetter] = useState<IMyCategoryContextState>(initialValues);

  return (
    <MyCategoryContext.Provider value={{
      ...myCategoryState,
      MyCategoryStateSetter
    }}>
      {children}
    </MyCategoryContext.Provider>
  );
}