import { createApi,fetchBaseQuery } from "@reduxjs/toolkit/query/react";





type AllAompetitionsResponse = {
	status: number;
	name: string;
	message: string;
	result: {
		data: {
			totalRecords: number;
			result: {
				id: string;
				title: string;
				display_order: number;
				display_flag: boolean;
			}[];
		};
	};
};



const categoriesAPI=createApi({
	reducerPath: "api/categories/competitions",
	baseQuery: fetchBaseQuery({ baseUrl: "/api/common/categories" }),
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: false,
	tagTypes: ["Api"],
	endpoints: (builder) => ({
		allCompetitions: builder.query<AllAompetitionsResponse,{}>({
			query: () => ({
				method: "GET",
				url: `competitions/vocabs`,
				cacheTime: 10000,	
			}),
		}),
	}),

	
})

export default categoriesAPI;

