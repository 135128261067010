import Toast from 'react-bootstrap/Toast';
import { ToastContainer } from 'react-bootstrap';
import { useNotificationContextWrapper } from '../contexts/NotificationContextWrapper';

function Notify() {
	const { state, hide } = useNotificationContextWrapper();
	const {canShow, message, type} = state;

	return (
		<ToastContainer position='top-end' className="p-3 position-fixed" style={{ zIndex: 1 }} >
			<Toast onClose={() => hide()} show={canShow} bg={type==='success'?'success':type==='danger'?'danger':''} delay={3000} autohide>
				<Toast.Header closeButton={true}>
					<strong className="me-auto">{type === 'success' ? 'Success' : 'Error'}</strong>
				</Toast.Header>
				<Toast.Body className='text-white'>{message}</Toast.Body>
			</Toast>
		</ToastContainer>
	);
}

export default Notify;