import { useEffect, useMemo, useState } from "react";
import { Layout } from "../../home/Layout"
import { MyCategoryDropdown } from "./CategoriesDropDown"
import { useUserContextWrapper } from "../../../shared/contexts/UserContextWrapper";
import { FlashCaredStyle } from "../flashcared";
import { MyCategoryCards } from "./MyCategoryCard";
import AddMyCategoryDialog from "./AddMyCategoryDialog";
import ShareMyCategoryDialog from "./SharedDialog";
import Notify from "../../../shared/components/Notify";
import api from "../../../store/services/personalCategoryAPI";

export const CustomCategories = () => {

  const { user } = useUserContextWrapper();
  const [modalShow, setModalShow] = useState(false);
  const [isSharedOpen, setSharedOpen] = useState(false);
  const [categoryName, setCategoryName] = useState({
    categoryName: '',
    categoryId: ''
  });
	const [fetch,{data}]=api.useFetchAllPersonalCategoriesMutation()

  const userId = useMemo(() => user?.userId ?? '', [user])
  useEffect(() => {
		if(!userId) return
		fetch({
			user_id:userId
		})
  }, [fetch, userId]);

 
  return (
    <Layout>
      <div style={{height:'calc(100vh - 220px)'}}>
      <MyCategoryDropdown text="my categories" />
      <div>
        <Notify />
        <AddMyCategoryDialog refetch={()=>{
					fetch({
						user_id:userId
					})
				
				}} show={modalShow} setShow={setModalShow} />
        <ShareMyCategoryDialog show={isSharedOpen} setShow={setSharedOpen} categoryName={categoryName} />
      </div>
      <div className="col-9 mx-auto overflow-auto h-100 scrollbar scrollbar-primary " >
        <div className="d-flex flex-wrap d-flex justify-content-start mt-3 "style={{gap:'0 4vw'}}>
          {data?.result?.data.result?.map(x => (
            <FlashCaredStyle key={x.customCategoryId} className="mt-3 d-inline">
              <MyCategoryCards
							refetch={()=>{
								fetch({
									user_id:userId
								})
							
							}}
                setCategoryName={setCategoryName}
                show={isSharedOpen}
                setShow={setSharedOpen}
                categoryId={x.customCategoryId}
                categoryName={x.categoryName}
              />
            </FlashCaredStyle>
          ))}
          <div className="d-flex align-self-end justify-content-start">
            <FlashCaredStyle key={1} onClick={() => setModalShow(!modalShow)} className="mt-3">
              <MyCategoryCards setCategoryName={setCategoryName} setShow={setSharedOpen} categoryName={'+'} />
            </FlashCaredStyle>
          </div>
        </div>
      </div>
      </div>
    </Layout>
  )
}

