/* eslint-disable jsx-a11y/alt-text */
import { Carousel, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { HomeStyle } from "./styles";
const StyledParagraph = styled.p`
	color: var(--app-color1);
`;

const StyledH1 = styled.h1`
	color: var(--app-color1);
`;

const StyledCarousel = styled(Carousel)`

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-image: none; /* Remove the background image */
  border: 4px solid #4B4D77;
  border-radius: 50%;
  height:3vw;
  width:3vw;
  background-size: 100%;
  /* Set the desired color for the icons */;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; 


}
.carousel-control-next,.carousel-control-prev{
  opacity:1 !important;
  text-decoration: none;
  display:flex;
  justify-content:end;
}
.carousel-control-next{
  justify-content:start !important;
}

.carousel-control-next-icon::before {
  content: ">"; /* Replace with right arrow character */
  font-size: 3vw; /* Adjust the font size as needed */
  color: #4B4D77;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /
  padding:0;
  
  
  

}

.carousel-control-prev-icon::before {
  content: "<"; /* Replace with right arrow character */
  font-size: 3vw; /* Adjust the font size as needed */
  color: #4B4D77;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /
  padding:0;

}
.carousel-indicators{
  display:none;
}




`;

export default function VokabDefinitionSection() {
	return (
		<HomeStyle>
			<Row
				className="m-0 d-flex flex-row justify-content-center align-items-center flex-wrap home-font col-12 "
				style={{ height: "calc(100vh - 130px)" }}
			>
				<Col xs={12} md={12} lg={6} xl={6} xxl={6} className="mx-auto">
					<div className="d-flex align-items-center justify-content-center">
						{/* <div className="me-4">
							<button className="border-0" style={nextButtonStyle}>
								<IoChevronBackCircleOutline
									className="IoChevronWidth"
									color="#57587F"
								/>
							</button>
						</div> */}
						<div className="text-center">
							<img src="/logo.png" className="img-width" />
						</div>
						{/* <div className="ms-4">
							<button className="border-0" style={nextButtonStyle}>
								<IoChevronForwardCircleOutline
									className="IoChevronWidth"
									color="#57587F"
								/>
							</button>
						</div> */}
					</div>
					<div
						style={{
							marginTop: "4rem",
						}}
						className="row  mx-4 gx-4 justify-content-center "
					>
						<div className="col">
							<a
								href="https://tinyurl.com/AndroidVokab "
								target="_blank"
								type="button"
								rel="noopener noreferrer"
							>
								<img
									src={"./Asserts/playstore.png"}
									className="img-fluid img-thumbnail"
								/>
							</a>
						</div>
						<div className="col">
							<a
								href="https://tinyurl.com/iOSVokab "
								target="_blank"
								rel="noopener noreferrer"
								type="button"
							>
								<img
									src={"./Asserts/appstore.png"}
									className="img-fluid img-thumbnail"
								/>
							</a>
						</div>
					</div>
					{/* <Carousel.Item className="text-center">
				<img src="/logo.png" className="img-width" />
			</Carousel.Item>
			<Carousel.Item className="text-center">
				<img src="/logo.png" className="img-width" />
			</Carousel.Item> */}
				</Col>
				<Col
					xs={12}
					md={12}
					lg={6}
					xl={6}
					xxl={6}
					className="d-flex justify-content-center align-items-center p-0 "
					style={{ height: "100%" }}
				>
					<div className="container-fluid margin-top_l p-0 col-8 ">
						<StyledH1 className="text-center">what is vokab?</StyledH1>
						<p className="lh-lg">
							<b>vokab</b> is a{" "}
							<b className="text-decoration-underline">free</b> and{" "}
							<b className="text-decoration-underline">multilingual</b>{" "}
							<b>vocabulary</b> software, designed to aid language-learning
							students <b>across the world</b>.
						</p>
						<p className="mt-4 lh-lg">
							Take advantage of <b>over 4900</b> unique and relevant words,
							quality checked and tagged for the{" "}
							<b>most accurate translation in 35+ different languages.</b>
						</p>
						<StyledParagraph className="link mt-5 anchor">
							for more initiatives, visit{" "}
							<a
								href="https://linguistics.world"
								target="_blank"
								rel="noreferrer"
							>
								www.linguistics.world
							</a>
						</StyledParagraph>
					</div>
				</Col>
			</Row>
		</HomeStyle>
	);
}
