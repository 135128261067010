import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";



type SharePersonnalCategoryArgs={
	toEmails?: string;
	fromUserId?: string;
	toUserIds: string[];
	customCategoryId: string;
}
type SharePersonnalCategoryResponse={
	status: number;
	name: string;
	message: string;
	result: {
		data: string;
	};
}


type PersonalCategoryDeleteResponseProps = {
	status: number;
	name: string;
	message: string;
	result: {
		data: string;
	};
};


type PersonalCategoryResponseProps = {
	status: number;
	name: string;
	message: string;
	result: {
		data: {
			totalRecords: number;
			result: {
				customCategoryId: string;
				categoryName: string;
				userId: string;
			}[];
		};
	};
};
type PersonalCategoryRequestParams = {
	user_id: string;
}


// Define a service using a base URL and expected endpoints
const personalCategoryApi = createApi({
	reducerPath: "api/personalCategory",
	// extractRehydrationInfo: (state) => state.api,
	keepUnusedDataFor: 60 * 60 * 1000, // 1 hour
	baseQuery: fetchBaseQuery({ baseUrl: "/api" }),
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: false,
	tagTypes: ["PersonalCategory"],

	endpoints: (builder) => ({
		fetchAllPersonalCategories: builder.mutation<PersonalCategoryResponseProps, PersonalCategoryRequestParams>({
			query: ({ user_id }) => ({
				url: `common/myCategories`,
				method: "POST",
				body: { userId: user_id },
				cacheTime: 10000,	
			}),
			transformResponse: (response:PersonalCategoryResponseProps) => {
				return response;
			},
			transformErrorResponse(error:any) {
				return error
			},
			
		}),
		sharePersonnalCategory: builder.mutation<SharePersonnalCategoryResponse,SharePersonnalCategoryArgs >({
			query: (args) => ({
				url: `common/shareMyCategory`,
				method: "POST",
				body: args,
				cacheTime: 10000,	
			}),
		
			transformErrorResponse(baseQueryReturnValue, meta, arg) {
				return baseQueryReturnValue.data as Error;
			},
			transformResponse(baseQueryReturnValue, meta, arg) {
				return baseQueryReturnValue as SharePersonnalCategoryResponse;
			},
		}),
		
		deletePersonalCategory: builder.mutation<PersonalCategoryDeleteResponseProps, string>({
			query: (id) => ({
				method: "DELETE",
				url: `common/myCategories/${id}`,
				cacheTime: 10000,

			}),
			transformResponse: (response:PersonalCategoryDeleteResponseProps) => {
				return response;
			},
			transformErrorResponse(error:any) {
				alert(error.message);
				return error
			},
			// Add an alert after the deleteCategory endpoint
		}),
	}),
});
export default personalCategoryApi;
