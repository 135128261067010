/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAppContextWrapper } from '../../../shared/contexts/AppContextWrapper';
import { FaShare } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { instance } from '../../../api';
import { useNotificationContextWrapper } from '../../../shared/contexts/NotificationContextWrapper';
import React, { useEffect, useState } from 'react';
import api from '../../../store/services/personalCategoryAPI';
interface IProps {
  categoryName: string;
  categoryId?: string;
  show?: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setCategoryName: React.Dispatch<React.SetStateAction<any>>;
	refetch?:()=>void
}

export const MyCategoryCards = ({ categoryName, categoryId, setShow, show, setCategoryName ,refetch}: IProps) => {
  const navigate = useNavigate();
  const { appStateSetter } = useAppContextWrapper();
	const {notifyError,notifySuccess}=useNotificationContextWrapper()
	const [showConfirmation,setShowConfirmation]=useState(false)
	const [fetch,{data,isLoading,error}]=api.useDeletePersonalCategoryMutation()
	
	useEffect(()=>{
		if(data){
			notifySuccess('category deleted successfully')
			setShowConfirmation(false)
			refetch?.()
		}
		if(error){
			notifyError(error)
		}
	},[data, isLoading, error, navigate])

	

  const onShared = () => {
    setCategoryName({ categoryName: categoryName, categoryId: categoryId })
    setShow(!show)
  }

  const handleClick = () => {
    if (categoryId) {
      appStateSetter(prev => ({
        ...prev, flashCardProfLevel: 'myCategory',
        flashCardsMyCustomCategory: categoryId
      }));
      navigate('/flashCards')
    }
  }
	const handleDeletePress = (categoryId:string) => setShowConfirmation(true)

	const handleCancelDelete=()=>{
		setShowConfirmation(false)
	}



  return (
		<React.Fragment>
    <Card className='h-100 col-9'>
      <Card.Body className='my_categories_font h-100 d-flex justify-content-between align-items-center flex-column'
      >
        <div onClick={handleClick} className='d-flex justify-content-center align-items-center h-100'>
          <Card.Text >
            {categoryName === '+' ? <FaPlus size={70} ></FaPlus> : categoryName}
          </Card.Text>
        </div>
        {categoryName !== '+' && categoryName !== 'favorites' &&
          <div className='d-flex justify-content-between align-items-end w-100'>
						<MdDelete onClick={()=>handleDeletePress(categoryId!)} className='text-primary' />

              <FaShare onClick={onShared} />

          </div>
        }
      </Card.Body >
    </Card >
		<Modal show={showConfirmation} onHide={handleCancelDelete}>
				<Modal.Header closeButton>
					<Modal.Title>confirmation</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					are you sure you want to delete this category? all the sharing will be deleted permanently.
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCancelDelete}>
						Cancel
					</Button>
					<Button variant="danger" onClick={()=>fetch(categoryId!)}>
						Delete
					</Button>
				</Modal.Footer>
			</Modal>
		
		</React.Fragment>
  )
}