import { faStop, faVolumeHigh } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Card, Form } from "react-bootstrap"
import { IoAddCircleOutline } from "react-icons/io5"
import { CategoryCardStyle } from "./categoryCardStyle"
import Notify from "../../../shared/components/Notify"
import { store, useAppSelector } from "../../../store"

interface IProps {
  cardBodyClick: () => void;
  word: string;
  speechHandler: () => void;
  onShow: () => void;
  isSpeaking: boolean;
	isTamilWord:boolean
}

export const MainCard = ({ cardBodyClick, word, speechHandler, onShow, isSpeaking,...props }: IProps) => {
	const autoPlay =useAppSelector(state=>state.lessons.autoPlay)

  return (
    <CategoryCardStyle>
      <Card>
        <Card.Body className="d-flex flex-column justify-content-between p-0">
          <div style={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }} onClick={cardBodyClick}>
            <Card.Text className={props.isTamilWord?'box-body ta-text':'box-body'} >
              {word}
            </Card.Text>
          </div>
          <div className="d-flex justify-content-end p-0 align-items-center">
            <Notify/>
            <Form>
              <Form.Check
                type="switch"
                id="custom-switch"
                checked={autoPlay}
                onChange={() => {
									store.dispatch({
										type:"lessons/setAutoPlay",
										payload:!autoPlay
									})
								}}
              />
            </Form>
            <FontAwesomeIcon onClick={speechHandler} icon={isSpeaking ? faStop : faVolumeHigh} size='2xl' className='me-3' />
            <IoAddCircleOutline onClick={onShow} className='plus_icon_width' />
          </div>
        </Card.Body>
      </Card>
    </CategoryCardStyle>
  )
}