import { Route, Routes } from "react-router-dom";
import { FlashcardsHome } from "../pages/flashCards";
import { FlashCardCategoriesHome } from "../pages/flashCards/categories";
import { FlashCardsContextWrapper } from "../shared/contexts/FlashCardContext";
import { CustomCategories } from "../pages/flashCards/myCategories";
import { CustomCategoriesHome } from "../pages/flashCards/myCategories/customCategories";
import { MyCategoryContextWrapper } from "../shared/contexts/MyCategoryContext";

export default function MyCategoryRouter() {
  return (
    <MyCategoryContextWrapper>
      <Routes>
        <Route path='/' element={<CustomCategories />} />
        <Route path='/cards' element={<CustomCategoriesHome />} />
      </Routes>
    </MyCategoryContextWrapper>
  )
}