import * as T from "@vokab/shared/src/types";
import { addMyCategoryDetailsRequest, allCategories, allLanguages, allUsers, categoriesByIdRequest, categoriesByProf, categoriesTableRequest, createMyCategoryPayload, grammarCategoryRequest, myCategoriesByUserIdRequest, mySharedCategoriesByUserIdRequest, profLevelsRequest, removeCategoryDetailsRequest, shareMyCategoryRequest } from "../../api/common";
import { useAppContextWrapper } from "../contexts/AppContextWrapper";
import { useNotificationContextWrapper } from "../contexts/NotificationContextWrapper";
import { sendForgotPassword } from "../../api/mailService";
import { store } from "../../store";
import { setGridDataFetching } from "../../store/slices/appSlice";


export const useAppHook = () => {
	const { appStateSetter } = useAppContextWrapper();
	const { notifyError, notifySuccess } = useNotificationContextWrapper();
	
	const getAllCategories = async () => {
		const [res] = await allCategories();
		appStateSetter(prev => ({ ...prev, categories: res?.result?.data }));
	}

	const getAllLanguages = async () => {
		const [res] = await allLanguages();
		appStateSetter(prev => ({ ...prev, languages: res?.result?.data }));
	}

	const getAllProfLevels = async () => {
		const [res] = await profLevelsRequest();
		appStateSetter(prev => ({ ...prev, profLevels: res?.result?.data }));
	}

	const getAllCategoriesGrid = async (filterObject: T.CategoriesTableFilterParams) => {
		store.dispatch(setGridDataFetching(true))
		const [res] =await categoriesTableRequest(filterObject)
		.finally(() => store.dispatch(setGridDataFetching(false)))
		appStateSetter(prev => ({ ...prev, categoriesTableData: res?.result?.data }));
	}

	const getCategoriesById = async (filterObject: T.CategoriesById) => {
		const [res] = await categoriesByIdRequest(filterObject);
		appStateSetter(prev => ({ ...prev, categoriesById: res?.result?.data }));;
	}
	const getCategoriesByProfLevel = async (filterObject: T.CategoriesByProfParams) => {
		appStateSetter(Prev =>({ ...Prev, isLoading:true}))
		try{
			const [res] = await categoriesByProf(filterObject);
			appStateSetter(prev => ({ ...prev, categoriesByProf:res?.result?.data }));
		}
		catch(err){
			console.log(err)
		}
		finally{
			appStateSetter(Prev =>({ ...Prev, isLoading:false}))
		}
	}

	const getAllGrammarCategory = async () => {
		const [res] = await grammarCategoryRequest();
		appStateSetter(prev => ({ ...prev, pos: res?.result?.data }));
	}

	const getAllMyCategoriesByUserId =async (filterObject: T.MyCategoriesByUserParams) => {
		const [res] = await myCategoriesByUserIdRequest(filterObject);
		appStateSetter(prev => ({ ...prev, myCategories: res?.result?.data }));
	}

	const getAllSharedCategoriesByUserId =async (filterObject: T.MyCategoriesByUserParams) => {
		const [res] = await mySharedCategoriesByUserIdRequest(filterObject);
		appStateSetter(prev => ({ ...prev, sharedCategoriesById: res?.result?.data }));
	}

		const createMyCategory = async (payload: T.IMyCategoryPayload) => {
		const [, error] = await createMyCategoryPayload(payload);
		if (error)
			notifyError({ message: error });
		else {
			notifySuccess('category added successfully');
		}
		}
	
	const addCategoryDetails = async (payload: T.IMyCategoryDetailsPayload) => {
		const [, error] = await addMyCategoryDetailsRequest(payload);
		if (error)
			notifyError({ message: error });
		else {
			notifySuccess('details added successfully');
		}
	}

		const sendForgotPasswordLink = async (payload: T.IForgotDetailsPayload) => {
		const [, error] = await sendForgotPassword(payload);
		if (error)
			notifyError({ message: error.response.data.message ?? '' });
		else {
			notifySuccess('reset password mail send successfully');
		}
		}
	
		const getAllUsers = async () => {
		const [res] = await allUsers();
		appStateSetter(prev => ({ ...prev, allUsers: res?.result?.data }));
		}
	
	const shareMyCategory = async (payload: T.IShareMyCategoryPayload) => {
		const [, error] = await shareMyCategoryRequest(payload);
		if (error)
			notifyError({ message: error });
		else {
			notifySuccess('category shared successfully');
		}
	}
	
	const removeCategoryDetails = async (payload:T.IRemoveWordPayload) => {
		const [, error] = await removeCategoryDetailsRequest(payload);
		if (error)
			notifyError({ message: error });
		else {
			notifySuccess('word removed successfully');
		}
	}

	return {
		getAllCategories,
		getAllLanguages,
		getAllProfLevels,
		getCategoriesByProfLevel,
		getAllCategoriesGrid,
		getAllGrammarCategory,
		getCategoriesById,
		getAllMyCategoriesByUserId,
		createMyCategory,
		addCategoryDetails,
		sendForgotPasswordLink,
		getAllUsers,
		shareMyCategory,
		getAllSharedCategoriesByUserId,
		removeCategoryDetails
	}
};