import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { IState } from ".";
import SelectDropDown from "../../shared/components/SelectDropDown";
import { useAppContextWrapper } from "../../shared/contexts/AppContextWrapper";
import { useUserContextWrapper } from "../../shared/contexts/UserContextWrapper";
import categoriesAPI from "../../store/services/categoriesAPI";
import device from "../../styled-components/device";
import { dropdownContainer } from "../home/styles";
import { FlashCaredStyle } from "./flashcared";

const MyDropdownContainer = styled.div`
	${dropdownContainer}
	${device.md} {
		padding: 2.5vw !important;
		font-size: 2vw;
	}
	${device.xs} {
		padding: 1.5vw !important;
		font-size: 2vw;
	}
`;

interface IProps {
	setState: Dispatch<SetStateAction<IState>>;
	state: IState;
}

export const MyCategoriesDropdown = ({ state, setState }: IProps) => {
	const {
		languages,
		profLevels,
		myCategories,
		appStateSetter,
		sharedCategoriesById,
		flashCardsSourceLang,
		flashCardsTargetLang,
		flashCardBowlCategory,
	} = useAppContextWrapper();
	const { isLoggedIn } = useUserContextWrapper();
	const onChange = (value: string) => {
		appStateSetter((prev) => ({ ...prev, flashCardProfLevel: value }));
		if (value !== "myCategory" && value !== "sharedCategory") {
			appStateSetter((prev) => ({ ...prev, flashCardsMyCustomCategory: "" }));
		}

		if (value !== "bowlCategory") {
			appStateSetter((prev) => ({ ...prev, flashCardBowlCategory: "" }));
		}
	};
	const onMyCategoryChange = (value: string) => {
		appStateSetter((prev) => ({ ...prev, flashCardsMyCustomCategory: value }));
		setState((prev: IState) => ({
			...prev,
			customCategoryId: value,
		}));
	};

	const myCategoryOption = isLoggedIn
		? [
				{
					value: "myCategory",
					display: "my categories",
				},
		  ]
		: [];

	const sharedCategoryOption =
		isLoggedIn && sharedCategoriesById?.result?.length
			? [
					{
						value: "sharedCategory",
						display: "shared categories",
					},
			  ]
			: [];
	const { data: competitions } = categoriesAPI.useAllCompetitionsQuery({});
	useEffect(() => {
		if (competitions?.result?.data?.result?.length) {
			appStateSetter((prev) => ({
				...prev,
				flashCardBowlCategory: competitions?.result?.data?.result[0]?.id,
			}));
		}
	}, [appStateSetter, competitions]);

	const options = profLevels
		.map((v) => ({
			value: v.profLevel,
			display: v.profLevel.toLowerCase(),
		}))
		.concat(myCategoryOption || [])
		.concat(sharedCategoryOption || []);
	// .concat([{
	// 	value: 'bowlCategory',
	// 	display: 'vokab bowl competitions'
	// }])

	const myCategoryOptions =
		state.profId === "sharedCategory"
			? sharedCategoriesById?.result?.map((v) => ({
					value: v.customCategoryId,
					display: v.categoryName.toLowerCase(),
			  }))
			: myCategories.result.map((v) => ({
					value: v.customCategoryId,
					display: v.categoryName.toLowerCase(),
			  }));
	const getLanguageOptions = (languageId: string) => {
		// const newElementsLanguages = newEle.map(kk => getHeaderDropDown(kk))
		// const languages = [langs.language1, langs.language2].concat(newElementsLanguages)
		// const currentLangFilter = languages.filter(d => d !== languageId)
		const filterOptions = groupingLanguages;
		// .filter(v => !currentLangFilter.includes(v.languageName))

		return filterOptions;
	};
	const languageOptions = useMemo(
		() =>
			[
				{
					languageName: "English",
					languageFamilyId: "",
					languageFamilyName: "default",
					languageId: "",
				},
			].concat(
				languages.map((d) => ({
					languageName: d.languageName,
					languageFamilyId: d.languageFamilyId,
					languageFamilyName:
						d.languageFamilyName.toLocaleLowerCase() === "others"
							? d.languageFamilyName
							: `${d.languageFamilyName} family`,
					languageId: d.languageId,
				}))
			) ?? null,
		[languages]
	);
	const groupingLanguages: { [key: string]: string[] } = languageOptions.reduce(
		(family: any, language) => {
			const key: string = language.languageFamilyName;
			if (!family[key]) {
				family[key] = [];
			}
			family[key].push({
				languageName: language.languageName,
				languageId: language.languageId,
			});
			return family;
		},
		[]
	);

	return (
		<>
			<FlashCaredStyle>
				<div className="container-fluid col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-12">
					<Row className="m-0 mt-3 justify-content-between align-items-center ">
						<Col className="p-0 col-12">
							<MyDropdownContainer className="d-flex flex-wrap justify-content-center align-items-center MyDropdownContainer my_vokab_table">
								{/* <div className="col-1 d-flex justify-content-center align-items-center">
                  <FaSearch className="icon-width" />
                </div> */}
								<div className="col-12">
									<SelectDropDown
										options={options}
										onChange={(value) => onChange(value)}
										value={state.profId}
									/>
								</div>
							</MyDropdownContainer>
						</Col>
					</Row>
					{isLoggedIn &&
						(state.profId === "myCategory" ||
							state.profId === "sharedCategory") && (
							<Row className="m-0 mt-3 justify-content-between align-items-center ">
								<Col className="p-0 col-12">
									<MyDropdownContainer className="d-flex flex-wrap justify-content-center align-items-center MyDropdownContainer my_vokab_table">
										{/* <div className="col-1 d-flex justify-content-center align-items-center">
                  <FaSearch className="icon-width" />
                </div> */}
										<div className="col-12">
											<SelectDropDown
												options={myCategoryOptions}
												onChange={(value) => onMyCategoryChange(value)}
												value={state.customCategoryId}
											/>
										</div>
									</MyDropdownContainer>
								</Col>
							</Row>
						)}
					{/* {
							state.profId==='bowlCategory'&&<Row className='m-0 mt-3 justify-content-between align-items-center '>
							<Col className="p-0 col-12">
								<MyDropdownContainer className="d-flex flex-wrap justify-content-center align-items-center MyDropdownContainer my_vokab_table">
									<div className="col-12">
										<SelectDropDown options={competitions?.result?.data?.result?.map?.((v)=>({value:v?.id,display:v?.title?.toLowerCase()}) )|| []} onChange={(value) => appStateSetter((prev) => ({
												...prev, flashCardBowlCategory: value
											}))}
											value={flashCardBowlCategory} />
									</div>
								</MyDropdownContainer>
							</Col>
						</Row>
						} */}
					<Row className="m-0 mt-3 justify-content-between">
						<Col className="p-0 col-6">
							<MyDropdownContainer className="d-flex flex-wrap justify-content-center align-items-center  MyDropdownContainer my_vokab_table">
								{/* <div className=" search col-1 d-flex justify-content-center align-items-center">
                  <FaSearch className="icon-width" />
                </div> */}
								<div className="search col-12  border-0 ">
									<FlashCaredStyle>
										<select
											value={flashCardsSourceLang}
											onChange={(e) =>
												appStateSetter((prev) => ({
													...prev,
													flashCardsSourceLang: e.target.value,
												}))
											}
											className="my_categories_font ps-0"
										>
											{/* {
                        Object.entries(getLanguageOptions(flashCardsSourceLang)).map((l, i) => (
                          <>
                            <option key={i} value={l[0]} disabled className="option"><b >{l[0].toLowerCase()}</b></option>
                            {l[1].map((x: any, y) => <option key={y} value={x.languageName}>{x.languageName.toLowerCase()}</option>)}
                          </>
                        ))
                      } */}
											{languages
												?.sort((a, b) =>
													a.languageName.localeCompare(b.languageName)
												)
												.map((l, i) => (
													<option key={i} value={l.languageName}>
														{l.languageName.toLowerCase()}
													</option>
												))}
										</select>
									</FlashCaredStyle>
								</div>
							</MyDropdownContainer>
						</Col>
						<Col className="p-0 col-6">
							<MyDropdownContainer className="d-flex flex-wrap  justify-content-center align-items-center MyDropdownContainer my_vokab_table">
								{/* <div className=" search col-1  d-flex justify-content-center align-items-center ">
                  <FaSearch className="icon-width" />
                </div> */}
								<div className=" search col-12 ">
									<FlashCaredStyle>
										<select
											value={flashCardsTargetLang}
											onChange={(e) =>
												appStateSetter((prev) => ({
													...prev,
													flashCardsTargetLang: e.target.value,
												}))
											}
											className="my_categories_font ps-0"
										>
											{/* {Object.entries(
												getLanguageOptions(flashCardsTargetLang)
											).map((l, i) => (
												<>
													<option
														key={i}
														value={l[0]}
														disabled
														className="option"
													>
														<b>{l[0].toLowerCase()}</b>
													</option>
													{l[1].map((x: any, y) => (
														<option key={y} value={x.languageName}>
															{x.languageName.toLowerCase()}
														</option>
													))}
												</>
											))} */}
											{languages
												?.sort((a, b) =>
													a.languageName.localeCompare(b.languageName)
												)
												.map((l, i) => (
													<option key={i} value={l.languageName}>
														{l.languageName.toLowerCase()}
													</option>
												))}
										</select>
									</FlashCaredStyle>
								</div>
							</MyDropdownContainer>
						</Col>
					</Row>
				</div>
				<hr className="col-11 mx-auto "></hr>
			</FlashCaredStyle>
		</>
	);
};
