import { getUser } from "../../api/user";
import { useUserContextWrapper } from "../contexts/UserContextWrapper";

export const useUserHook = () => {
	const { userStateSetter } = useUserContextWrapper();

	const getMe = async () => {
		const authToken = localStorage.getItem('authToken');
		if (!authToken)
			return null;
		const [res, error] = await getUser();
		if (error)
			userStateSetter(prev => ({ ...prev, isLoggedIn: false, user: null }));
		else
			userStateSetter(prev => ({ ...prev, isLoggedIn: true, user: res.result.data }));
	}
	
	return {
		getMe
	}
};