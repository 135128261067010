import { Link, useNavigate } from "react-router-dom";
import { useUserContextWrapper } from "../../shared/contexts/UserContextWrapper";
import {
	Button,
	Col,
	Dropdown,
	DropdownButton,
	Nav,
	NavDropdown,
	Navbar,
	OverlayTrigger,
	Row,
	Tooltip,
} from "react-bootstrap";
import { useMemo, useEffect } from "react";
import { useAppContextWrapper } from "../../shared/contexts/AppContextWrapper";
import { useAppHook } from "../../shared/hooks/useAppHook";
import authApi from "../../store/services/authAPI";

import { BsPersonFill } from "react-icons/bs";
import { IoIosNotifications } from "react-icons/io";
import sharedCategoryAPI from "../../store/services/sharedCategoryAPI";

function NavbarActions() {
	const { isLoggedIn, user } = useUserContextWrapper();
	const [logout] = authApi.useUseSignoutMutation();

	const navigate = useNavigate();
	const { getAllSharedCategoriesByUserId } = useAppHook();
	const { sharedCategoriesById } = useAppContextWrapper();
	const userId = useMemo(() => user?.userId ?? "", [user]);
	const [fetch, data] =
		sharedCategoryAPI.useGetAllUnDeliveredByReceiverIdMutation();

	useEffect(() => {
		if (userId)
			Promise.all([getAllSharedCategoriesByUserId({ userId: userId })]).then();
		if (user?.userId) fetch({ to_user_id: user?.userId }).then();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId]);
	if (isLoggedIn)
		return (
			<Nav.Link>
				<Nav className="align-items-center position-relative justify-content-end">
					<div
						className={`${
							data?.data?.result?.data?.totalRecords ? "visible" : "invisible"
						}`}
					>
						<OverlayTrigger
							placement="left"
							overlay={
								<Tooltip>
									{data?.data?.result?.data?.totalRecords} unread shared
									categories
								</Tooltip>
							}
						>
							<span
								style={{
									position: "absolute",
									top: "12px",
									right: "20px",
								}}
								className={`translate-middle badge rounded-pill bg-light text-dark`}
							>
								{data?.data?.result?.data?.totalRecords}
							</span>
						</OverlayTrigger>
					</div>

					<NavDropdown
						id="nav-dropdown-dark-example"
						title={<BsPersonFill />}
						menuVariant="light"
					>
						<NavDropdown.Item onClick={() => navigate("/myCategories")}>
							my categories
						</NavDropdown.Item>
						{sharedCategoriesById?.result?.length >= 1 && (
							<Dropdown.Item onClick={() => navigate("/sharedCategory")}>
								shared categories
							</Dropdown.Item>
						)}
						<NavDropdown.Item onClick={() => navigate("/myProfile")}>
							my profile
						</NavDropdown.Item>
						<NavDropdown.Item onClick={() => navigate("/ChangePassword")}>
							change password
						</NavDropdown.Item>
						<NavDropdown.Item onClick={() => logout()}>
							log out
						</NavDropdown.Item>
					</NavDropdown>
				</Nav>
			</Nav.Link>
		);

	return (
		<Row >
			<Col lg={6} xs={12}>
				<Nav.Link as={Link} to="/auth/login">
					<Button size="sm">log in</Button>
				</Nav.Link>
			</Col>

			<Col lg={6} xs={12}>
				<Nav.Link as={Link} to="/auth/signup">
					<Button size="sm">sign up</Button>
				</Nav.Link>
			</Col>
		</Row>
	);
}

export default NavbarActions;
