import { useEffect, useMemo } from "react";
import { useAppHook } from "../../shared/hooks/useAppHook";
import { Layout } from "../home/Layout"
import CategoryListTable from "./CategoryListTable"
import { VocabularyTable } from "./vocabularyTable";
import { useUserContextWrapper } from "../../shared/contexts/UserContextWrapper";
import Notify from "../../shared/components/Notify";

export const VocabularyHome = () => {
  const { getAllLanguages, getAllProfLevels, getAllCategories, getAllGrammarCategory } = useAppHook();
  const { getAllMyCategoriesByUserId } = useAppHook();
  const { user } = useUserContextWrapper();
  const userId = useMemo(() => user?.userId ?? '', [user])

  useEffect(() => {
    if (userId)
      Promise.all([
        getAllMyCategoriesByUserId({ userId: userId })
      ]).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  useEffect(() => {
    Promise.all([
      getAllCategories(),
      getAllLanguages(),
      getAllProfLevels(),
      // getAllUsers(),
      getAllGrammarCategory()
    ]).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  return (
    <VocabularyTable>
      <div>
        <Notify/>
        <Layout>
          <div className="mt-3 mx-auto">
            <CategoryListTable />
          </div>
        </Layout>
      </div>
    </VocabularyTable>
  )
}