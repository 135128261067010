import React, { useContext, createContext, useState } from 'react';
import { IAuthUser } from '@vokab/shared/src/types';

export interface IUserContextState  {
	isLoggedIn: boolean;
	user: IAuthUser | null;
}

export interface UserContextProps {
	children: React.ReactNode;
}

const initialValues = {
	isLoggedIn: false,
	user: null
};

export interface IUserContext extends IUserContextState {
	userStateSetter: React.Dispatch<React.SetStateAction<IUserContextState>>;
}

const UserContext = createContext<IUserContext | null >(null);

export const useUserContextWrapper = () => useContext(UserContext) as IUserContext;

export const UserContextWrapper = ({ children }: UserContextProps) => {
	const [userState, userStateSetter] = useState<IUserContextState>(initialValues);
	
	return (
		<UserContext.Provider value={{
			...userState,
			userStateSetter
		}}>
			{children}
		</UserContext.Provider>
	);
}