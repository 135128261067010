import { Route, Routes } from "react-router-dom";
import { FlashcardsHome } from "../pages/flashCards";
import { FlashCardCategoriesHome } from "../pages/flashCards/categories";
import { FlashCardsContextWrapper } from "../shared/contexts/FlashCardContext";

export default function FlashcardsRouter() {
  return (
    <FlashCardsContextWrapper>
      <Routes>
        <Route path='/' element={<FlashcardsHome />} />
        <Route path='/categories' element={<FlashCardCategoriesHome />} />
      </Routes>
    </FlashCardsContextWrapper>
  )
}