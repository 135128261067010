import Modal from 'react-bootstrap/Modal';
import { ISelected } from './CategoryListTable';
import { useAppHook } from '../../shared/hooks/useAppHook';
import { useAppContextWrapper } from '../../shared/contexts/AppContextWrapper';

interface IProps {
    removeModelShow: boolean;
    setRemoveModelShow: React.Dispatch<React.SetStateAction<boolean>>;
    state: ISelected;
    customCategoryId: string;
    loadGridData: () => void;
}

const RemoveMyCategoryDialog = ({ removeModelShow, setRemoveModelShow, state, customCategoryId, loadGridData }: IProps) => {
    const { removeCategoryDetails } = useAppHook();
    const { myCategories } = useAppContextWrapper();

    const ccid = myCategories.result.find(d => d.categoryName === customCategoryId)

    const removeWord = async () => {
        await removeCategoryDetails({ id: ccid?.customCategoryId ?? '', word: state.selectedWord })
        setRemoveModelShow(!removeModelShow)
        loadGridData();
    }

    return (
        <Modal
            removeModelShow={removeModelShow}
            onHide={() => setRemoveModelShow(!removeModelShow)}
            animation={false} show={removeModelShow}
            centered>
            <Modal.Header closeButton>
                <Modal.Title>remove word</Modal.Title>
            </Modal.Header>
            <Modal.Body>are you sure want to remove the  <b>{state.selectedWord}</b> word from <b>{ccid?.categoryName}</b> category</Modal.Body>
            <Modal.Footer>
                <button onClick={() => setRemoveModelShow(!removeModelShow)}>
                    cancel
                </button>
                <button onClick={removeWord}>
                    remove word
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default RemoveMyCategoryDialog;