import { ICategoryById } from "@vokab/shared/src/types";
import { useEffect, useMemo, useState } from "react";
import {
	IoChevronBackCircleOutline,
	IoChevronForwardCircleOutline,
} from "react-icons/io5";
import { useAppContextWrapper } from "../../../shared/contexts/AppContextWrapper";
import { useFlashCardsContextWrapper } from "../../../shared/contexts/FlashCardContext";
import { useAppHook } from "../../../shared/hooks/useAppHook";
import { Layout } from "../../home/Layout";
import { CategoryCard } from "./CategoryCard";
import { CategoryCardStyle } from "./categoryCardStyle";
import { nextButtonStyle } from "./style";

export const getLanguageNameCode = (value: string, code: string) => {
	if (value === "English") return "en";
	else return code ?? "en";
};
export const FlashCardCategoriesHome = () => {
	const {
		categories,
		targetLanguage,
		sourceLanguage,
		categoryName,
		customCategoryId,
	} = useFlashCardsContextWrapper();
	const { getCategoriesById } = useAppHook();
	const [index, setIndex] = useState(0);
	// const [autoPlay, setAutoPlay] = useState(false);
	const { categoriesById } = useAppContextWrapper();
	const totalCount = useMemo(
		() => categoriesById.result.length,
		[categoriesById]
	);
	const selectedValues = {
		selectedWord: categoriesById.result[index]?.english,
		grammarAssociationId:
			categoriesById.result[index]?.vocabularyGrammarAssociationId,
		categoryId: categoriesById.result[index]?.categoryId,
	};
	useEffect(() => {
		if (categories) {
			Promise.all([
				getCategoriesById({
					categoryId: categories,
					sourceLanguage: sourceLanguage,
					targetLanguage: targetLanguage,
					customCategoryId: customCategoryId,
				}),
			]).then();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categories, sourceLanguage, targetLanguage]);

	const getLanguageName = (
		value: string,
		lang: ICategoryById,
		word: string
	) => {
		if (value === "English") return lang?.english;
		else return word ?? "No Data";
	};

	return (
		<Layout>
			<CategoryCardStyle>
				{
					<div
						className="d-flex justify-content-center align-items-center flex-column"
						style={{ height: "calc(100vh - 110px)" }}
					>
						<div>
							<p
								style={{
									fontWeight: "bold",
								}}
								className="box-heading-font"
							>
								{categoryName}
							</p>
						</div>
						<div
							style={{
								display: "flex",
								alignItems: "center",
							}}
						>
							<div className="me-4">
								<button
									className="border-0"
									onClick={() => index > 0 && setIndex(index - 1)}
									style={nextButtonStyle}
								>
									{" "}
									<IoChevronBackCircleOutline
										className="IoChevronWidth"
										color="#57587F"
									/>
								</button>
							</div>

							<div>
								{
									<CategoryCard
										sourceLanguage={
											categoriesById?.result[index]?.language1 ||
											categoriesById?.result?.[index]?.english
										}
										sourceLanguageCode={
											categoriesById?.result?.[index]?.language1Code || "en"
										}
										targetLanguage={
											categoriesById?.result[index]?.language2 ||
											categoriesById?.result?.[index]?.english
										}
										targetLanguageCode={
											categoriesById?.result?.[index]?.language2Code || "en"
										}
										selectedValues={selectedValues}
										language1VocId={
											categoriesById?.result[index]?.language1VocId ||
											categoriesById?.result[index]?.vocabularyId
										}
										language2VocId={
											categoriesById?.result[index]?.language2VocId ||
											categoriesById?.result[index]?.vocabularyId
										}
										index={index}
									/>
								}
							</div>
							{/*  */}
							<div className="ms-4">
								<button
									className="border-0"
									onClick={() =>
										index < categoriesById.result.length - 1 &&
										setIndex(index + 1)
									}
									style={nextButtonStyle}
								>
									<IoChevronForwardCircleOutline
										className="IoChevronWidth"
										color="#57587F"
									/>
								</button>
							</div>
						</div>
						<div className="size pt-2">
							<p className="box-footer-font">
								{totalCount >= 1 ? `${index + 1} / ${totalCount}` : "0/0"}
							</p>
						</div>
					</div>
				}
			</CategoryCardStyle>
		</Layout>
	);
};
