import { Any } from '@vokab/shared/src/types';
import React, { useContext, createContext, useState } from 'react';
import { getApiErrorDataMessage } from '../../api';

export interface INotificationContextState  {
	canShow: boolean;
	type: string;
	message: Any;
}

export interface NotificationContextProps {
	children: React.ReactNode;
}

const initialValues = {
	canShow: false,
	message: '',
	type: ''
};

export interface INotificationContext {
	state: INotificationContextState;
	notifySuccess: (message: string) => void;
	notifyError: (message: Any) => void;
	hide: () => void;
}

const NotificationContext = createContext<INotificationContext | null >(null);

export const useNotificationContextWrapper = () => useContext(NotificationContext) as INotificationContext;

export const NotificationContextWrapper = ({ children }: NotificationContextProps) => {
	const [notificationState, notificationStateSetter] = useState<INotificationContextState>(initialValues);

	return (
		<NotificationContext.Provider value={{
			state: notificationState,
			notifySuccess: (message: string) => {
			return	notificationStateSetter({
					message,
					type : 'success',
					canShow : true
				})
			},
			notifyError: (message: Any) => {
			return	notificationStateSetter({
					message : getApiErrorDataMessage(message),
					type : 'danger',
					canShow : true
				})
			},
			hide: () => {
				notificationStateSetter({
					message: '',
					type: '',
					canShow: false
				})
			},
		}}>
			{children}
		</NotificationContext.Provider>
	);
}