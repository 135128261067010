import styled from "styled-components";

export const CustomStyle = styled.div`
	
    .btn:hover{
        background:#4B4D77;
        border-color:#4B4D77;
        color:white !important;
    }
`;
