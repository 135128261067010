import * as yup from 'yup';

export const emailPattern = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

export const passwordPattern = /^(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}$/;

export const mobilePattern = /^[6789]\d{9}$/;

export const checkEmail = () => yup.string()
	.matches(emailPattern, 'Email must be in the format of user@domain.ext')
	.required('Please enter an email address');

export const checkStringRequired = () => yup.string().required('Please enter this field');

export const checkStringOptional = (min = 3, max = 75) => yup.string().nullable()
	.transform((o, c) => o === '' ? null : c)
	.min(min, `Field must have at least ${min} characters`)
	.max(max, `Field must have maximum ${max} characters`);

export const checkStringRequiredLength = (min = 3, max = 75) => checkStringOptional(min, max).required('Please enter this field');

export const checkPassword = () => yup.string()
	.required('Please enter a password')
	.matches(passwordPattern, `password must have a minimum of 8 characters and contain at least one uppercase letter,
 	one lowercase letter, one number, and one special character ( ex: Test@123 )`);

export const checkConfirmPassword = () => yup.string()
	.required('Please re-enter a password')
	.oneOf([yup.ref('password'), ''], 'Passwords must match');

export const checkPhoneNumber = () => yup.string().required('Please enter this field')
	.matches(mobilePattern, 'Phone number must contain 10 digits');