import { NationBowlStyle } from './nationVokabStyle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';


const BowlSection = () => {

  return (
    <NationBowlStyle className='mt-3'>
      <div className="container-fluid col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-12 d-flex justify-content-center align-items-center" style={{height:'100%'}}>
        <div className="bowl mx-auto">
          <p className="cmn-font-h2 text-center  m-0"><b>vokab bowl</b></p>
          <p className="cmn-font ">
            Welcome to the <b>vokab bowl</b>, your chance to showcase your mastery of words from various languages. Get ready for thrilling challenges, friendly competition, and the opportunity to win prestigious titles and prizes.
          </p>
          <p className="cmn-font "><b>What to Expect:</b></p>
          <p className="cmn-font ">
            <b className='me-2'>
              <FontAwesomeIcon icon={faArrowRight} className='me-4' />Exciting Rounds:
            </b>
            Test your vocabulary skills in a variety of challenges, from synonyms to false friends.
          </p>
          <p className="cmn-font ">
            <b className='me-2'>
              <FontAwesomeIcon icon={faArrowRight} className='me-4' />Open to All:
            </b>
            No matter your language proficiency, everyone is welcome to join the competition.
          </p>
          <p className="cmn-font ">
            <b className='me-2'>
              <FontAwesomeIcon icon={faArrowRight} className='me-4' />Language Celebration:
            </b>
            Immerse yourself in the beauty of languages and connect with fellow language enthusiasts.
          </p>
          <p className="cmn-font text-center ">
            Register and stay tuned for updates on our upcoming <b>vokab bowl</b> event!
          </p>
          <div className="cmn-button text-center text-center " >
            <a href='https://docs.google.com/forms/d/1zErJvOtPhHYf55ACb1IeiuWiWWw1QAJp5BYybRFqmw4/edit?usp=drive_web'
              target='_blank'
              type="submit"
              className="btn btn-secondary cmn-font  rounded-5 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-5  mx-auto p-0"
            >
              <b className='mx-auto w-100'>Register</b>
              {/* <b onClick={() => navigate('/auth/sign up')}>Register</b> */}
            </a>
          </div>
        </div>
      </div>
    </NationBowlStyle>
  );
};

export default BowlSection;
