import styled from "styled-components";

export const NationBowlStyle = styled.div`


 
 
.cmn-button .btn {
	color:#474871;
	background-color: #9895AD !important;
  text-transform:lowercase;
  font-size:1.9vw;
  border:3px solid #474871;
};
.cmn-button .btn:hover{
  background-color: #4B4D77 !important;
  color:white !important;
  border-color:#4B4D77 !important; 
}
@media  (max-width:549px){
  .cmn-font {
    font-size: 4vw;
  }
  .cmn-font-h2 {
      font-size: 6vw;
  }
  .cmn-button .btn{
    font-size:5vw;
  }
  .container-fluid{

    text-align:justify;
   }
  
 

}
  @media (max-width: 767px) and (min-width:550px){
    
    .cmn-font {
      font-size: 3vw;
    }
    .cmn-font-h2 {
        font-size: 6vw;
    }
    .cmn-button .btn{
      font-size:3.5vw;
    }
    .container-fluid{
      overflow-y:auto;
      text-align:justify;
     }
     .py-4{
      padding:0 !important;
    }
    .p-3{
      padding:0 !important;
    
    }
    
  
   
  }

  @media screen and (max-width: 991px) and (min-width: 768px) {
    .cmn-font {
        font-size: 2.5vw;
      }
      .cmn-font-h2 {
          font-size: 4vw;
      }
      .cmn-button .btn{
        font-size:3vw;
      }
     
      .py-4{
        padding:0 !important;
      }
      .p-3{
        padding:0 !important;
      
      }
      .container-fluid{
        overflow-y:auto;
        text-align:justify;
       }
      
    
     
  }

  @media (max-width: 1200px) and (min-width: 992px) {
    .cmn-font {
        font-size: 2vw;
      }
      .cmn-font-h2 {
          font-size: 4vw;
      }
      .container-fluid{
        overflow-y:auto;
        text-align:justify;
       }
      
     
  }
  @media (min-width: 1201px) {
    
  }
`;
